<template align-center class="pa-0">

<v-container class="pa-0 mb-4">

    <v-row class="mt-1">

        <v-col cols="6" sm="12" md="6" lg="6" xl="6">
        <v-card>

          <v-toolbar>
             <v-icon color="indigo" class="mx-1">person</v-icon> 
            <v-toolbar-title>Asignaciones por Ejecutivos</v-toolbar-title>
          </v-toolbar>

         
            <v-card-text>
                    <apexchart width="480"  height="230"  type="bar" :options="optionsClientes" :series="seriesCliente"></apexchart>  
            
             <v-divider class="mt-2"></v-divider>
                    <v-icon  class="mr-2" small>account_circle</v-icon>
                    <span>Total clientes:
                      <v-chip class="ma-2"  dark color="red"> {{ cantidadTotalClientes }}</v-chip>
                    </span>

                     <v-icon  class="mr-2" small>drive_eta</v-icon>
                    <span >Total vehiculos:
                      <v-chip class="ma-2"  dark color="indigo"> {{ CantidadtotalVehiculos }}</v-chip>
                    </span>

            
            </v-card-text>
        </v-card>
        </v-col>

      <v-col cols="6" sm="12" md="6" lg="6" xl="6">
        <v-card>

            
          <v-toolbar>
             <v-icon color="indigo" class="mx-1">label</v-icon> 
            <v-toolbar-title>Estados por ejecutivo</v-toolbar-title>

          </v-toolbar>


            <v-card-text>
                    <apexchart width="480" height="230" type="bar" :options="optionsEstados" :series="seriesEstados"></apexchart>


                    <v-divider class="mt-2"></v-divider>
                    <v-icon  class="mr-2" small>drive_eta</v-icon>
                    <span>vehiculos seguimiento:
                      <v-chip class="ma-2"  dark color="green"> {{ CantidadtotalVehiculosEstado }}</v-chip>
                    </span>
                   

                     <v-icon  class="mr-2" small>drive_eta</v-icon>
                    <span>vehiculos Desconocidos:
                      <v-chip class="ma-2"  dark color="red"> {{ CantidadtotalVehiculosDesc }}</v-chip>
                    </span>


            </v-card-text>
        </v-card>
      </v-col>

    </v-row>

     <v-row>

    <v-data-table
    :headers="headers"
    :items="ArrayVehiculos"
    sort-by="vence_estado"
     :search="search"
    class="elevation-1 mt-2 mx-1 mb-6"
  >
    <template v-slot:top>
      <v-toolbar flat color="opcion1">
        <v-toolbar-title>Vehiculos estados vencidos</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>

          <v-flex  xs4 sm4 md3 lg3>
          <v-text-field  justify-center  v-model="search"  append-icon="search" 
          label="Busqueda" single-line hide-details></v-text-field> 
        </v-flex>
        
        <v-spacer></v-spacer>

        <v-btn color="success" @click="listar();">
              <v-icon>restart_alt</v-icon>
              </v-btn>

        <v-dialog v-model="dialog" max-width="700px">
        
          <v-card>
          
            <v-toolbar color="red">
             <v-toolbar-title class="white--text">{{ formTitle }} : {{  editedItem.nombre}}  / {{  editedItem.placa}}</v-toolbar-title>
             <v-spacer></v-spacer>
             <v-icon dark @click="dialog=false">close</v-icon>
             </v-toolbar>



            <v-card-text>
              <v-container>
                <v-row>


              
                   <v-col cols="12"  sm="6" md="6">
                    <v-select
                    prepend-icon="settings"
                              v-model="editedItem.id_estado"
                              :items="ArrayEstados"
                              label="Estado"
                          ></v-select>
                  </v-col>


                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="editedItem.fecha_gestion"
                      prepend-icon="today"
                      label="Fecha gestion"
                      :value="editedItem.fecha_gestion"
                       disabled
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="editedItem.contactado"
                      prepend-icon="person"
                      label="Contactado"
                    ></v-text-field>
                  </v-col>

               
                  <v-col cols="12" sm="6" md="4">
                   

                     <v-menu
                        ref="menu1"
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                            v-model="editedItem.vence_estado"
                            label="Fecha vence estado"
                            prepend-icon="date_range"
                            v-bind="attrs"
                            v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="editedItem.vence_estado"
                            no-title
                            @input="menu1 = false"
                        ></v-date-picker>
                        </v-menu>

                  </v-col>


                   <v-col cols="12" sm="12" md="12">
                      <v-textarea
                        name="observacion"
                        label="observacion"
                        v-model="editedItem.observacion"
                        rows="1"
                        prepend-icon="comment"
                        >
                      </v-textarea>
                  </v-col>



                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
              <v-btn  color="success" @click="save"> Guardar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)" color="green">edit</v-icon>
    </template>


    <template v-slot:[`item.fecha_tx`]="{ item }">
      <span>{{  getDate(item.fecha_tx) }}</span>
    </template>


    <template v-slot:[`item.ubicacion`]="{ item }">
      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
    </template>




       <template v-slot:[`item.id_estado`]="{ item }">

         <v-chip v-if="item.id_estado.nombre_estado=='Desconocido'" small color="red" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


         <v-chip v-else-if="item.id_estado.nombre_estado=='Sin confirmar'" small color="red" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


         <v-chip v-else small color="blue" outlined>
           <span>{{ item.id_estado.nombre_estado }}</span>
         </v-chip>


    </template>


   
    <template v-slot:[`item.vence_estado`]="{ item }">
           <span v-if="item.vence_estado!=null">{{ new Date(item.vence_estado).toISOString().substr(0, 10) }}</span>
    </template>


     <template v-slot:[`item.fecha_gestion`]="{ item }">
           <span v-if="item.fecha_gestion!=null">{{ getDate(item.fecha_gestion) }}</span>
    </template>


    <template v-slot:no-data>
      <v-btn  color="primary" @click="listarVehiculos()">
        Reset
      </v-btn>
    </template>
  </v-data-table>


    </v-row>


    

</v-container>
    
</template>




<script>

import axios from 'axios';
import { mapMutations,mapState } from "vuex";

import VueApexCharts from 'vue-apexcharts';



export default {
  components:{
     apexchart: VueApexCharts
  },
  data(){
         return{

           cantidadTotalClientes:0,
           CantidadtotalVehiculos:0,
           CantidadtotalVehiculosEstado:0,
           CantidadtotalVehiculosDesc:0,

             ArrayEjecutivos:[],
             ArrayClientes:[],

           label_local_ejecutivos:[],

            optionsClientes:{},
            seriesCliente:[],
            data_local_clientes:[],

            optionsEstados:{},
            seriesEstados:[],
            label_local_estados:[],
          

             seriesVehiculos:[],
             data_local_Vehiculos:[],

            
             EjecutivosArrayItems:[{text:'Todos', value:0}],
              ejecutivo_select:'',


             dialog: false,
             search:'',
            ArrayVehiculos:[],
            headers: [
              { text: 'nombre', value: 'nombre' },
              { text: 'Placa', value: 'placa' },
              { text: 'Flota', value: 'flota' },

              { text: 'Fecha tx', value: 'fecha_tx' },
              { text: 'Ubicacion', value: 'ubicacion' },

              { text: 'Estado', value: 'id_estado' },
              { text:'Fecha gestion', value:'fecha_gestion'},

              { text:'Contactado', value:'contactado'},
              { text:'Vence estado', value:'vence_estado'},
              { text:'Observacion', value:'observacion'},
              { text:'Cliente', value:'id_veh_cliente.nombre_cliente'},

              { text: 'Acciones', value: 'actions', sortable: false },       


            ],

         ArrayEstados:[],
         ArrayEstadosTodos:[{text:'Todos', value:0}],
         menu1:false,
         date: new Date().toISOString().substr(0, 10),

          editedIndex: -1,
      editedItem: {

        id_avl:'',
        nombre:'',
        placa:'',
        flota:'',
        icono:'',
        fecha_gps:'',
        fecha_tx:'',
        evento:'',
        velocidad:'',
        direccion:'',
        latitud:'',
        longitud:'',
        id_estado:'',
        fecha_gestion:new Date(),
        contactado:'',
        vence_estado:'',
        observacion:'',
        id_veh_cliente:'',
        comentario:''

      },
      defaultItem: {
        
        id_avl:'',
        nombre:'',
        placa:'',
        flota:'',
        icono:'',
        fecha_gps:'',
        fecha_tx:'',
        evento:'',
        velocidad:'',
        direccion:'',
        latitud:'',
        longitud:'',
        id_estado:'',
        fecha_gestion:new Date(),
        contactado:'',
        vence_estado:'',
        observacion:'',
        id_veh_cliente:'',
        comentario:''
      },
        

         }
 }, 

 created () {

  this.listarEjecutivos();

  this.ejecutivo_select=0;
 
  
 },
  watch: {
      dialog (val) {
        val || this.close()
      }
    },

 methods:{

      ...mapMutations(['mostrarLoading','ocultarLoading']),



       listarEjecutivos () {

      this.label_local_ejecutivos=[];

        let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('ejecutivos',
          config
          ).then(function(response){
             console.log(response);
             me.ArrayEjecutivos=response.data;





             me.ArrayEjecutivos.map(function(x){  

               if(x.rol=="ATC"){

                me.label_local_ejecutivos.push(x.nombre_ejecutivo);
                me.EjecutivosArrayItems.push({text:x.nombre_ejecutivo, value:x._id});

              }
              
             });



             


                me.optionsClientes={
                                     chart:{
                                        height: 350,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: false,
                                         enabledOnSeries: [1]
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  me.label_local_ejecutivos,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };


              me.listarClientes();
              me.listarEstados();
              me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

        
       },

       listarClientes(){

         this.data_local_clientes=[];
         this.data_local_Vehiculos=[];

         let config={headers:{token:this.token}};
         this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('clientes',
         config
          ).then(function(response){
             console.log(response);
             me.ArrayClientes=response.data;


            me.ArrayEjecutivos.map(function(x){  


                 if(x.rol=="ATC"){


                      let cantidad=0;
                      let sumaVehi=0;

                      me.ArrayClientes.map(function(y){

                        if(y.id_ejecutivo_cli._id==x._id){
                            cantidad++;
                            sumaVehi=parseFloat(y.cantidad_vehiculos)+sumaVehi;
                        }

                      });

                 me.data_local_clientes.push(cantidad);
                 me.data_local_Vehiculos.push(sumaVehi);
                 me.CantidadtotalVehiculos=me.CantidadtotalVehiculos+sumaVehi;

                 }
             });

               me.seriesCliente=[{
                            name: 'clientes por ejecutivo',
                            data: me.data_local_clientes
                        },
                        {
                            name: 'Vehiculos por ejecutivo',
                            data: me.data_local_Vehiculos
                       }
                        
                        ];


               me.seriesVehiculos=[{
                            name: 'Vehiculos por ejecutivo',
                            data: me.data_local_Vehiculos
               }];         


              me.cantidadTotalClientes=me.ArrayClientes.length;         




           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },



      listarVehiculos () {

         this.seriesEstados=[];

         let newDate = Date.now() + -1*24*3600*1000; // date 1 days ago in milliseconds
              newDate = new Date(newDate)

        let config={headers:{token:this.token}};
        this.mostrarLoading({titulo:'Accediendo a datos'});

        this.ArrayVehiculos=[];

         let me=this;
          axios.get('vehiculos',
         config
          ).then(function(response){
             console.log(response);

            

                //recorremos todos los vehiculos
                let arreglo=response.data;
                 me.CantidadtotalVehiculosEstado=arreglo.length;

                  let desconocidos=0;

                arreglo.map(function(x){

                  //si la fecha de vencimiento es mayor que ayer.

                    if(x.vence_estado!=null){

                      let d = new Date(x.vence_estado);
                       if(d.getTime() < newDate.getTime()){
                         //si es admin moestrar todo-- si no debeos filtrar por ejecutivo

                         if((me.usuario.rol=='ADMIN')||(x.id_veh_cliente.id_ejecutivo_cli==me.usuario._id)){
                              me.ArrayVehiculos.push(x);
                         }
                           
                       }
                        
                    }


                    if(x.id_estado.nombre_estado=='Desconocido'){
                      desconocidos++;
                    }
                });

                me.CantidadtotalVehiculosDesc=desconocidos;

               

                     //listamos por ejecutivo

                    me.ArrayEjecutivos.map(function(z){ 


                      if(z.rol=="ATC"){

                      let array_datos_estado=[];

                       //recorremos estados
                            me.label_local_estados.map(function(x){
                              
                              let contador_estado=0;

                              console.log('estado validando:'+x);

                                  arreglo.map(function(y){
                                      if((y.id_estado.nombre_estado==x)&&(y.id_veh_cliente.id_ejecutivo_cli==z._id)){
                                        contador_estado++;
                                        console.log('sumando contador:'+contador_estado);
                                      }
                                  });

                                  array_datos_estado.push(contador_estado);

                            });


                             me.seriesEstados.push({
                                      name: z.nombre_ejecutivo,
                                      data: array_datos_estado
                              });


                              }

                    });

                   
                   
                

                





             
          
           me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });

      
      },


      listarEstados(){

        
           
            this.label_local_estados=[];

            let config={headers:{token:this.token}};
           this.mostrarLoading({titulo:'Accediendo a datos'});

         let me=this;
          axios.get('estados',
          config
          ).then(function(response){
             console.log(response);
           
             let arreglo=response.data;

             arreglo.map(function(x){
               me.ArrayEstados.push({text:x.nombre_estado, value:x._id});
               me.ArrayEstadosTodos.push({text:x.nombre_estado, value:x._id});

                  if(x.nombre_estado!='Listo'){
                      me.label_local_estados.push(x.nombre_estado);
                  }
               
             });


                me.optionsEstados={
                                     chart:{
                                        height: 350,
                                        type: 'bar',
                                      },
                                      plotOptions: {
                                        bar: {
                                          horizontal: false,
                                          columnWidth: '55%'
                                        },
                                      },
                                      
                                       title: {
                                        text: ''
                                      },
                                        subtitle: {
                                        text: '',
                                        align: 'center',
                                    },

                                      dataLabels: {
                                        enabled: false,
                                         enabledOnSeries: [1]
                                      },

                                      stroke: {
                                        show: true,
                                        width: 2,
                                        colors: ['transparent']
                                      },
                                      xaxis: {
                                        categories:  me.label_local_estados,
                                      },

                                      
                                     yaxis:[],
                                      fill: {
                                            opacity: 1
                                          }    


                                   };



            me.listarVehiculos();

            me.ocultarLoading();
          }).catch(function(error){
            console.log(error);
          });


      },


       editItem (item) {
        this.editedIndex = this.ArrayVehiculos.indexOf(item)
        this.editedItem = Object.assign({}, item)

        let me=this;

        this.editedItem.fecha_gestion=me.getDate(new Date());
        this.editedItem.id_estado=item.id_estado._id;

        if(this.editedItem.vence_estado!=null){
           this.editedItem.vence_estado= new Date(this.editedItem.vence_estado).toISOString().substr(0, 10)
        }

        this.dialog = true
      },

       close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

        save () {
        if (this.editedIndex > -1) {
          //Object.assign(this.ArrayVehiculos[this.editedIndex], this.editedItem)  
          //edicion de vehiculos


                 //actualizar datos de item
                    let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
             
                    let id=this.editedItem._id;
                    let me=this;

                    axios.put(`vehiculos/${id}`,
                      { 
                       '_id':this.editedItem._id,
                       'id_estado':this.editedItem.id_estado,
                       'fecha_gestion':new Date(),
                       'contactado':this.editedItem.contactado,
                       'vence_estado':this.editedItem.vence_estado,
                       'observacion':this.editedItem.observacion
                     
                       },
                       config

                        ).then(response=>{
                      
                        console.log(response);
                        this.guardarHistorial();
                         me.ocultarLoading();
                      
                        this.close();

                  }).catch(e=>{
                        console.log(e)
                        this.mensaje=e
                  });








        } else {
          //this.ArrayVehiculos.push(this.editedItem)
          //nuevo registro de vehiculo


        
        
        }
       
      },

      ActualizarGraficaEstado(){

      },

      getDate(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day,
      hour = date.getHours().toString(),
      formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
      minute = date.getMinutes().toString(),
      formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
      second = date.getSeconds().toString(),
      formatedSecond = (second.length === 1) ? ("0" + second) : second;
    return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
    },

      getDateVence(evaluar) {
      let date = new Date(evaluar),
      year = date.getFullYear(),
      month = (date.getMonth() + 1).toString(),
      formatedMonth = (month.length === 1) ? ("0" + month) : month,
      day = date.getDate().toString(),
      formatedDay = (day.length === 1) ? ("0" + day) : day;
    return formatedDay + "-" + formatedMonth + "-" + year;
    },

    openGoogleMap(item){
        const urlSuffix = item.latitud +','+ item.longitud;
        window.open(
          "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
          "_blank"
        );
    },

      guardarHistorial(){

             
               //nuevo ejecutivo

                   let config={headers:{token:this.token}};
                     this.mostrarLoading({titulo:'Accediendo a datos'});
                     let me=this;

                axios.post('nuevo-historico',
                   {
                      id_avl:this.editedItem.id_avl,
                      nombre:this.editedItem.nombre,
                      placa:this.editedItem.placa,
                      flota:this.editedItem.flota,
                      icono:this.editedItem.icono,
                      fecha_gps:this.editedItem.fecha_gps,
                      fecha_tx:this.editedItem.fecha_tx,
                      evento:this.editedItem.evento,
                      velocidad:this.editedItem.velocidad,
                      direccion:this.editedItem.direccion,
                      latitud:this.editedItem.latitud,
                      longitud:this.editedItem.longitud,

                      id_estado:this.editedItem.id_estado,
                      
                      fecha_gestion:new Date(),
                      contactado:this.editedItem.contactado,
                      vence_estado:this.editedItem.vence_estado,
                      observacion:this.editedItem.observacion,

                      id_veh_cliente:this.editedItem.id_veh_cliente,


                      activo:1,
                      creado:new Date(),
                      actualizado:this.editedItem.actualizado,
                      comentario:'usuario:'+this.usuario.nombre_ejecutivo

                   },
                       config
                ).then(response=>{
                
                  this.close();
                  console.log(response);
                  this.listar();

                   me.ocultarLoading();

                }).catch(e=>{
                      console.log(e)
                    
                });

    }





       



 },


 mounted(){
   
 },
 computed:{
       ...mapState(['usuario', 'token']),

      formTitle () {
        return this.editedIndex === -1 ? 'Nuevo vehiculo' : 'Edicion'
      },
 }
}
</script>
